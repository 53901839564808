<template>
<div class="container-fluid mt-3">
  <Form @submit="saveTag" v-slot="{ errors }">
    <div class="d-flex mb-3 justify-content-between align-items-center flex-wrap">
      <Breadcrumb  :parent_page="{title: 'الأوسمة' , path: '/all-tags'}" current_page="إضافة وسم"/>
      <div class="controls-btns d-flex gap-2 mb-2">
        <button class="btn btn-publish" type="submit" @click="saveTag" :disabled="loading">حفظ</button>
      </div>
    </div>

    <el-card class="custom-card">
      <el-tabs v-model="activeTab">
        <el-tab-pane label="الوسم" name="tagDetails">
          <div class="row my-3">
            <div class="col-lg-4">
              <div class="form-group">
                <label for="name">اسم الوسم</label>
                <Field
                    name="name"
                    id="name"
                    v-model="tag.name"
                    placeholder="اسم الوسم"
                    class="form-control form-control-solid"
                    :rules="isRequired"
                    :class="{'border-red' : errors.name }"
                />
                <span class="text-error">{{ errors.name }}</span>
              </div>

              <div class="form-group mt-3">
                <label for="description">وصف الوسم</label>
                <textarea
                    rows="5"
                    v-model="tag.description"
                    name="description"
                    id="description"
                    class="form-control input-rounded form-control-solid"
                    placeholder="وصف الوسم"
                ></textarea>
              </div>
            </div>
          </div>

          <label class="mb-0">خصائص الوسم</label>
            <div>

              <el-checkbox v-model="tag.is_index" class="me-0 my-3 rounded-0" label="مؤرشف؟"
                           border></el-checkbox>
              <el-checkbox v-model="tag.is_follow" class="me-0 my-3 rounded-0" label="متابع من قوقل؟"
                           border></el-checkbox>
              <el-checkbox v-model="tag.is_disabled" class="me-0 my-3 rounded-0" label="معطل؟"
                           border></el-checkbox>
            </div>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </Form>
</div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import {Form, Field} from "vee-validate";
import {createTag} from "@/services/tags";
import {useToast} from "vue-toastification";

export default {
  name: "add_tag",
  components: {
    Breadcrumb,
    Form,
    Field
  },
  data() {
    return {
      toast: useToast(),
      loading: false,
      activeTab: 'tagDetails',
      tag: {
        name: '',
        is_disabled: false,
        is_follow: false,
        is_index: false,
        description: ''
      }
    }
  },
  methods: {
    isRequired(value)
    {
      return value ? value.length < 3 ? 'يجب ألا يقل الاسم عن 3 أحرف.' : true : 'الرجاء ادخال اسم الوسم';
    },
    saveTag(){
      if(this.tag.name.length >= 3) {
        this.loading = true
        createTag(this.tag)
        .then(()=>{
          this.loading = false
          this.$router.push('/all-tags')
          this.toast.success("تمت الإضافة بنجاح", {
            position: "top-center",
          });
        })
        .catch(()=>{
          this.loading = false
        })
      }
    }
  }
}

</script>

<style>
.el-tabs__nav {
  float: right;
}

[dir=rtl] .el-dialog__headerbtn {
  right: unset;
  left: 20px;
}

.el-tabs__item {
  padding-right: 0;
  padding-left: 20px !important;
}

.el-checkbox.is-bordered {
  margin: 0;
  border-radius: 0;
  height: 42px;
}

.el-checkbox__label {
  padding-right: 8px;

}

</style>
